import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Pager } from '../../model/pager';
import { PagesChangeValue } from '../../model/page-changes-value';
@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  $count: number;
  pageSizeCtrl = new FormControl(10);
  lstPageSize = [5, 10, 25, 50, 100];
  currentPage = 1;
  pager: Pager = new Pager();
  @Output() pagesChange: EventEmitter<PagesChangeValue> = new EventEmitter<PagesChangeValue>();
  public firstLoad = true;
  interval: any;
  public pageSize = 10;
  public pageIndex: number;
  @Input() set count(value: number) {
    this.$count = value;
    if (value === 0) {
      this.firstLoad = true;
    }
    this.changePages();
  }
  constructor() { }


  ngOnInit(): void {
    this.pageSizeCtrl.valueChanges.subscribe(x => { this.changePages(); });
    // this.changePages();
    // this.changePages();
  }
  public onFirstLoad(): void {
    this.firstLoad = true;
    this.pageIndex = 0;
    this.currentPage = 1;
  }
  changePages(): void {

    let pageSize = Number(this.pageSizeCtrl.value);
    if (!pageSize) {
      pageSize = 10;
    }
    this.pager = this.getPager(this.$count, pageSize);
    this.pageIndex = this.currentPage - 1;
    this.pageSize = Number(pageSize);
    if (this.$count) {
      if (!this.firstLoad) {
        this.pagesChange.emit(new PagesChangeValue({
          pageIndex: this.currentPage - 1,
          pageSize: Number(this.pager.pageSize)
        }));
      } else {
        this.firstLoad = false;
      }
    }
  }

  getPager(totalItems: number, pageSize: number = 10): Pager {
    // calculate total pages
    const result = new Pager();
    if (!totalItems) {
      return result;
    }
    result.pageSize = pageSize;
    result.totalItems = totalItems;
    result.totalPages = Math.ceil(totalItems / pageSize);
    if (this.currentPage > result.totalPages) {
      this.currentPage = 1;
    }
    if (result.totalPages <= 5) {
      result.startPage = 1;
      result.endPage = result.totalPages;
    } else {
      if (this.currentPage <= 3) {
        result.startPage = 1;
        result.endPage = 5;
      } else if (this.currentPage + 1 >= result.totalPages) {
        result.startPage = result.totalPages - 3;
        result.endPage = result.totalPages;
      } else {
        result.startPage = this.currentPage - 1;
        result.endPage = this.currentPage + 1;
      }
    }

    if (result.startPage >= 3) {
      result.isShowDotsPrefix = true;
    } else if (result.startPage === 2) {
      result.isShowFirst = true;
    }
    if (result.endPage <= result.totalPages - 2) {
      result.isShowDotsSuffix = true;
    } else if (result.endPage === result.totalPages - 1) {
      result.isShowLast = true;
    }
    return result;
  }
  getPages(): number[] {
    const lst: number[] = [];
    if (this.pager) {
      for (let index = this.pager.startPage; index <= this.pager.endPage; index++) {
        lst.push(index);
      }
    }
    return lst;

  }
  setCurrentPage(currentPage: number): void {
    this.currentPage = currentPage;
    this.changePages();
  }
  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.changePages();
    }
  }
  nextPage(): void {
    if (this.currentPage < this.pager.totalPages) {
      this.currentPage++;
      this.changePages();
    }
  }
}
