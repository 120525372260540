import { Injectable } from '@angular/core';
import { CRMTinhThanhClient, CRM_TinhThanhDto } from '../api-connector/sso-connector';
import { change_alias } from '../functions';

@Injectable({
  providedIn: 'root'
})
export class TinhThanhService {
  lstCrmTinhThanh: CRM_TinhThanhDto[] = [];

  constructor(
    private crmTinhThanhClient: CRMTinhThanhClient
  ) {
    this.getAll();
  }

  private getAll() {
    this.crmTinhThanhClient.getAll().subscribe(x => {
      this.lstCrmTinhThanh = x;
    });
  }

  filterCRMWithTag(value: string = ''): CRM_TinhThanhDto[] {

    const filterValue = change_alias(value).trim();
    const res: CRM_TinhThanhDto[] = [];
    this.lstCrmTinhThanh.forEach(x => {
      let point = 0;

      if (x.tenDayDuKhongDau.includes(filterValue)) {
        point += 3;
      }

      if (x.maTinhThanh === value) {
        point += 10;
      }

      let isMatch = true;
      let tagPoint = 0;
      filterValue.split(' ')
        .filter(y => y !== '')
        .forEach(y => {
          const tag = x.lstSearchTag.find(z => z.key === y);
          if (tag != null) {
            tagPoint += tag.point;
          } else {
            isMatch = false;
          }
        });

      x.point = isMatch ? point + tagPoint : point;

      if (x.point >= 3) {
        res.push(x);
      }
    });
    return res.sort((x, y) => y.point - x.point);
  }

  findByCode(value: any): any {
    if (this.lstCrmTinhThanh.length == 0) {
      this.getAll();
    }
    if (value) {
      const array = this.lstCrmTinhThanh.filter(option => option.maTinhThanh === value);
      return array.length > 0 ? array[0] : null;
    }
    return null;
  }
  findNameByCode(value: string = ''): string {
    if (value) {
      const array = this.lstCrmTinhThanh.filter(option => option.maTinhThanh === value);
      return array.length > 0 ? array[0].tenTinhThanh : '';
    }
    return '';
  }
}
