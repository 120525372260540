import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { CheckNameExistsRequest, ProductUnitClient } from 'src/app/shared/api-connector/sso-connector';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-add-product-unit-dialog',
  templateUrl: './add-product-unit-dialog.component.html',
  styleUrls: ['./add-product-unit-dialog.component.scss']
})
export class AddProductUnitDialogComponent implements OnInit {
  nameCtrl = new FormControl('', {
    validators: [Validators.required],
    updateOn: 'change',
    asyncValidators: [this.validatePositionNameNotTaken.bind(this)]
  });
  constructor(
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<AddProductUnitDialogComponent>,
    private alertService: AlertService,
    private productUnitClient: ProductUnitClient
  ) { }

  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close();
  }

  validatePositionNameNotTaken(control: FormControl): any {
    if (control.value) {
      return this.productUnitClient.checkProductUnitNameExists(new CheckNameExistsRequest({
        name: this.nameCtrl.value
      })).pipe(
        map(res => {
          return res ? { nameTaken: true } : null;
        }));
    } else {
      return of(null);
    }
  }

  onSubmit() {
    if (this.nameCtrl.valid) {
      this.spinner.show();
      this.productUnitClient.create(this.nameCtrl.value)
        .pipe(finalize(() => this.spinner.hide()))
        .subscribe(x => {
          this.alertService.success('Thêm mới đơn vị thành công', 'Thêm đơn vị');
          this.dialogRef.close(this.nameCtrl.value);
        });
    }
  }
}
