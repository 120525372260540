import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { SupplierClient, SupplierDto } from '../../api-connector/sso-connector';

@Component({
  selector: 'app-supplier-control',
  templateUrl: './supplier-control.component.html',
  styleUrls: ['./supplier-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SupplierControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SupplierControlComponent),
      multi: true,
    }
  ]
})
export class SupplierControlComponent implements OnInit {
  control = new FormControl('');
  @Input() label: string;
  @Input() required: boolean;
  @Input() submitted: boolean;
  @Input() isOutputText: boolean;
  @Input() disable: boolean;
  @Input() onlyParent: boolean = false;
  @Input() reload: string = '';

  @ViewChild('inputAutoSupplier', { read: MatAutocompleteTrigger }) autoComplete: MatAutocompleteTrigger;
  @ViewChild('inputAutoSupplier') input: ElementRef;

  private onDestroy$: Subject<void> = new Subject<void>();
  filtered: Observable<SupplierDto[]>;
  @Output() selectedSupplier: EventEmitter<SupplierDto> = new EventEmitter();
  supplierDto: SupplierDto;
  constructor(
    private supplierClient: SupplierClient
  ) { }

  ngOnInit(): void {
    this.onFiltered();
  }
  outputValue(): void {
    let value = this.control.value;
    if (!value || !(value instanceof SupplierDto) && !this.isOutputText) {
      value = null;
    }
    this.onChange(value);
    this.onTouched();
  }
  optionSelected(event: MatAutocompleteSelectedEvent) {
    const selectedValue = event.option.value;
    this.supplierDto = selectedValue;
    // console.log(this.productDto);
    this.selectedSupplier.emit(this.supplierDto);
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
  onChange: any = (value: any) => { };
  onTouched: any = () => { };

  writeValue(obj: string | SupplierDto): void {
    if (obj instanceof SupplierDto) {
      this.control.setValue(obj);
    }
    if (obj === null || obj === undefined || obj === '') {
      this.control.reset();
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }
  display = (category?: SupplierDto) => {
    return category ? `${category.name}` : '';
  }
  validate(c: FormControl): any {
    return this.control.errors;
  }

  onFiltered(){
    this.filtered = this.control.valueChanges.pipe(
      distinctUntilChanged(),
      startWith<string | SupplierDto>(''),
      map(value => value ? value : ''),
      map(value => typeof value === 'string' ? value : value.name),
      switchMap(value => {
        // if (!value) {
        //   return [];
        // }

        let result = this.supplierClient.getSupplierByKeySearch(value);
        return result;
      })
    );
    this.control.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.outputValue());
  }

  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/
    //Write your code here
    // console.log("reload", this.reload);
    if (this.reload != '' && this.reload != null && this.reload != undefined) {
      this.onFiltered();
    }
  }
  focusout() {
    if (!this.isOutputText) {
      const value = this.control.value;
      if (!value || !(value instanceof SupplierDto) && !this.isOutputText) {
        this.input.nativeElement.value = '';
      }
    }
    setTimeout(() => {
      this.autoComplete.closePanel();
    }, 200);
  }
}
