import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { PositionClient, PositionDto } from '../../api-connector/sso-connector';

@Component({
  selector: 'app-position-control',
  templateUrl: './position-control.component.html',
  styleUrls: ['./position-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PositionControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PositionControlComponent),
      multi: true,
    }
  ]
})
export class PositionControlComponent implements OnInit {
  control = new FormControl('');
  @Input() label: string;
  @Input() required: boolean;
  @Input() submitted: boolean;
  @Input() isOutputText: boolean;
  @Input() disable: boolean;
  @Input() reload: string = '';

  @ViewChild('inputAutoPosition', { read: MatAutocompleteTrigger }) autoComplete: MatAutocompleteTrigger;
  @ViewChild('inputAutoPosition') input: ElementRef;

  private onDestroy$: Subject<void> = new Subject<void>();
  filtered: Observable<PositionDto[]>;

  constructor(
    private PositionClient: PositionClient
  ) { }

  ngOnInit(): void {
    this.onFiltered();
  }
  outputValue(): void {
    let value = this.control.value;
    if (!value || !(value instanceof PositionDto) && !this.isOutputText) {
      value = null;
    }
    this.onChange(value);
    this.onTouched();
  }
  optionSelected(event: MatAutocompleteSelectedEvent) {
    // console.log(event);
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
  onChange: any = (value: any) => { };
  onTouched: any = () => { };

  writeValue(obj: string | PositionDto): void {
    if (obj instanceof PositionDto) {
      this.control.setValue(obj);
    }
    if (obj === null || obj === undefined || obj === '') {
      this.control.reset();
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }
  display = (Position?: PositionDto) => {
    return Position ? `${Position.name}` : '';
  }
  validate(c: FormControl): any {
    return this.control.errors;
  }
  focusout() {
    if (!this.isOutputText) {
      const value = this.control.value;
      if (!value || !(value instanceof PositionDto) && !this.isOutputText) {
        this.input.nativeElement.value = '';
      }
    }
    setTimeout(() => {
      this.autoComplete.closePanel();
    }, 200);
  }

  onFiltered() {
    this.filtered = this.control.valueChanges.pipe(
      distinctUntilChanged(),
      startWith<string | PositionDto>(''),
      map(value => value ? value : ''),
      map(value => typeof value === 'string' ? value : value.name),
      switchMap(value => {
        // if (!value) {
        //   return [];
        // }
        let result = this.PositionClient.getPositionByKeySearch(value);
        return result;
      })
    );
    this.control.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.outputValue());
  }

  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/
    //Write your code here
    // console.log("reload", this.reload);
    if (this.reload != '' && this.reload != null && this.reload != undefined) {
      this.onFiltered();
    }
  }
}
