import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { ListOrderCustomerResponseModel, OrderCustomerClient, OrderCustomerSearchModel, OrderCustomerStatusEnum } from 'src/app/shared/api-connector/sso-connector';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DetailOrderCustomerComponent } from '../detail-order-customer/detail-order-customer.component';

@Component({
  selector: 'app-danh-sach-don-hang',
  templateUrl: './danh-sach-don-hang.component.html',
  styleUrls: ['./danh-sach-don-hang.component.scss']
})
export class DanhSachDonHangComponent implements OnInit {
  myForm = new FormGroup({
    keySearchCtrl: new FormControl(''),
    fromCtrl: new FormControl(),
    toCtrl: new FormControl(''),
    statusCtrl: new FormControl(''),
    isUsingPrescriptionCtrl: new FormControl(''),
    createdIdCtrl: new FormControl(''),
    importerIdCtrl: new FormControl(''),
    orderChannelCtrl: new FormControl(''),
    paymentMethodCtrl: new FormControl(''),
    productCtrl: new FormControl('')
  });
  scrollStrategy: ScrollStrategy;
  advancePage: number;
  isShowPaginator: boolean;
  totalOrder = 0;
  totalOrderDiscount = 0;
  totalOrderPay = 0;
  productQty = 0;
  pageSize = 10;
  ctrls = this.myForm.controls;
  count: number;
  items: ListOrderCustomerResponseModel[] = [];
  isSpinner = true;
  isOrdered: boolean;
  constructor(
    public dialogRef: MatDialogRef<DanhSachDonHangComponent>,
    private spinner: NgxSpinnerService,
    private orderCustomerClient: OrderCustomerClient,
    private readonly scrollStrategyOptions: ScrollStrategyOptions,
    private dialog: MatDialog,
    private auth: AuthService,
  ) {
    this.advancePage = 1;
  }
  ngOnInit(): void {
    this.onSubmit(this.advancePage);
    this.scrollStrategy = this.scrollStrategyOptions.noop();
  }
  close() {
    this.dialogRef.close();
  }

  generateColorStatus(item: ListOrderCustomerResponseModel): string {
    let result = '';
    switch (item.statusId) {
      case OrderCustomerStatusEnum.CANCEl:
        result = 'badge badge-danger';
        break;
      case OrderCustomerStatusEnum.DONE:
        result = 'badge badge-success';
        break;
      case OrderCustomerStatusEnum.WAITING_ORDER_CONFIRM:
        result = 'badge badge-secondary';
        break;
      case OrderCustomerStatusEnum.DRAFT:
        result = 'badge badge-info';
        break;
      case OrderCustomerStatusEnum.Paid:
        result = 'badge badge-success';
        break;
    }

    return result;
  }

  onSubmit(advancePage) {
    this.isSpinner = true;
    this.spinner.show();
    this.items = [];
    this.orderCustomerClient.lstOrderForCustomer(new OrderCustomerSearchModel({
      keySearch: null,
      from: null,
      to: null,
      status: null,
      isUsingPrescription: null,
      createdId: this.auth.getAccount().id,
      importedId: null,
      orderChannel: null,
      paymentMethod: null,
      productId: null,
      isOrdered: true,
      pageIndex: advancePage - 1,
      pageSize: this.pageSize,
    }))
      .pipe(finalize(() => {
        this.spinner.hide();
        this.isSpinner = false;
        this.isShowPaginator = true;
      }))
      .subscribe(x => {
        console.log(x);
        this.count = x.count;
        this.items = x.items;
        this.totalOrder = x.totalMoney;
        this.totalOrderPay = x.payingAmount;
      });
  }

  onOpenDetail(item: ListOrderCustomerResponseModel) {
    const dialogRef = this.dialog.open(DetailOrderCustomerComponent, {
      panelClass: 'custom-dialog',
      minWidth: '90px',
      width: '50%',
      disableClose: true,
      scrollStrategy: this.scrollStrategy,
      data: item
    });
  }


  loadPage(advancePage) {
    this.onSubmit(advancePage)
  }
}
