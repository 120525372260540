import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { ProductClient, ProductDto, ProductPickupClient } from '../../api-connector/sso-connector';
import { AuthService } from '../../services/auth.service';
import { UploadService } from '../../services/upload.service';

@Component({
  selector: 'app-product-search-control',
  templateUrl: './product-search-control.component.html',
  styleUrls: ['./product-search-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProductSearchControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ProductSearchControlComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSearchControlComponent implements OnInit {
  control = new FormControl('');
  @Input() label: string;
  @Input() isSearchBuDon: boolean = false;
  @Input() required: boolean;
  @Input() submitted: boolean;
  @Input() isOutputText: boolean;
  @Input() isHomepage: boolean = false;
  @Input() isShowPriceCost: boolean = false;
  @Input() disable: boolean;
  @Input() reload: string = '';
  @ViewChild('inputAutoProduct', { read: MatAutocompleteTrigger }) autoComplete: MatAutocompleteTrigger;
  @ViewChild('inputAutoProduct') input: ElementRef;

  private onDestroy$: Subject<void> = new Subject<void>();
  filtered: Observable<ProductDto[]>;
  productDto: ProductDto;
  @Output() selectedProduct: EventEmitter<ProductDto> = new EventEmitter();
  constructor(
    private ProductClient: ProductClient,
    private uploadService: UploadService,
    private productPickupClient: ProductPickupClient,
    public authService : AuthService
  ) {

  }

  ngOnInit(): void {
    this.onFiltered();
  }
  outputValue(): void {
    let value = this.control.value;
    if (!value || !(value instanceof ProductDto) && !this.isOutputText) {
      value = null;
    }
    this.onChange(value);
    this.onTouched();

  }
  optionSelected(event: MatAutocompleteSelectedEvent) {
    const selectedValue = event.option.value;
    this.productDto = selectedValue;
    // console.log(this.productDto);
    this.selectedProduct.emit(this.productDto);
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
  onChange: any = (value: any) => { };
  onTouched: any = () => { };

  writeValue(obj: string | ProductDto): void {
    if (obj instanceof ProductDto) {
      this.control.setValue(obj);
    }
    if (obj === null || obj === undefined || obj === '') {
      this.control.reset();
    }

  }

  registerOnChange(fn: any): void {
    this.onChange = fn;

  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;

  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();

  }

  displayName = (Position?: ProductDto) => {
    return Position ? `${Position.title}` : '';
  }

  display = (Position?: ProductDto) => {
    return Position ? Position : {};
  }

  validate(c: FormControl): any {
    return this.control.errors;
  }

  getUrlFile(id: string) {
    return this.uploadService.getUrlFile(id);
  }

  focusout() {
    if (!this.isOutputText) {
      const value = this.control.value;
      if (!value || !(value instanceof ProductDto) && !this.isOutputText) {
        this.input.nativeElement.value = '';
      }
    }
    setTimeout(() => {
      this.autoComplete.closePanel();
    }, 200);
  }

  onFiltered() {
    this.filtered = this.control.valueChanges.pipe(
      distinctUntilChanged(),
      startWith<string | ProductDto>(''),
      map(value => value ? value : ''),
      map(value => typeof value === 'string' ? value : value.title),
      switchMap(value => {
        // if (!value) {
        //   return [];
        // }
        let result = this.ProductClient.getProductByKeySearch(value, this.isHomepage, this.isSearchBuDon);
        return result;
      })
    );
    this.control.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.outputValue());
  }

  ngOnChanges(changes: SimpleChanges) {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/
    //Write your code here
    // console.log(this.reload);
    if (changes.reload) {
      this.onFiltered();
    }
    // if (this.reload != '' && this.reload != null && this.reload != undefined) {
    //   console.log(this.reload);
    //   this.onFiltered();
    // }
  }
}
