export class TabOrderValue<T, E> {
    public key: any;
    public name: string;
    public data: T;
    public content?: E;
    public isRemove: boolean;
    constructor(data: ITabOrderValue<T, E>) {
        this.key = data?.key;
        this.data = data.data;
        this.content = data?.content;
        this.name = data?.name;
        this.isRemove = data?.isRemove;
    }
}
export interface ITabOrderValue<T, E> {
    key: any;
    isRemove: boolean;
    data: T;
    content?: E;
    name: string;
}
