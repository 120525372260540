import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    data: {
      title: 'Dashboard',
      breadcrumb: 'Dashboard',
    },
    loadChildren: () =>
      import('../../module/dashboard/dash-board.module').then(
        (m) => m.DashBoardModule
      ),
  },
  {
    path: 'quan-ly-nguoi-dung',
    data: {
      title: 'Quản lý người dùng',
      breadcrumb: 'Quản lý người dùng',
    },
    loadChildren: () =>
      import('../../module/quan-ly-nguoi-dung/quan-ly-nguoi-dung.module').then(
        (m) => m.QuanLyNguoiDungModule
      ),
  },
  {
    path: 'quan-ly-danh-muc',
    data: {
      title: 'Quản lý danh mục',
      breadcrumb: 'Quản lý danh mục',
    },
    loadChildren: () =>
      import('../../module/quan-ly-danh-muc/quan-ly-danh-muc.module').then(
        (m) => m.QuanLyDanhMucModule
      ),
  },
  {
    path: 'quan-ly-san-pham',
    data: {
      title: 'Quản lý sản phẩm',
      breadcrumb: 'Quản lý sản phẩm',
    },
    loadChildren: () =>
      import('../../module/quan-ly-san-pham/quan-ly-san-pham.module').then(
        (m) => m.QuanLySanPhamModule
      ),
  },
  {
    path: 'purchase-order',
    data: {
      title: 'Nhập hàng',
      breadcrumb: 'Nhập hàng',
    },
    loadChildren: () =>
      import('../../module/purchase-order/purchase-order.module').then(
        (m) => m.PurchaseOrderModule
      ),
  },
  {
    path: 'invoice',
    data: {
      title: 'Hóa đơn',
      breadcrumb: 'Hóa đơn',
    },
    loadChildren: () =>
      import('../../module/quan-ly-invoice/quan-ly-invoice.module').then(
        (m) => m.QuanLyInvoiceModule
      ),
  },
  {
    path: 'nhap-hang',
    data: {
      title: 'Nhân viên bù đơn',
      breadcrumb: 'Nhân viên bù đơn',
    },
    loadChildren: () =>
      import('../../module/quan-ly-nhap-hang/quan-ly-nhap-hang.module').then(
        (m) => m.QuanLyNhapHangModule
      ),
  },
  {
    path: 'thu-chi',
    data: {
      title: 'Quản lý thu chi',
      breadcrumb: 'Quản lý thu chi',
    },
    loadChildren: () =>
      import('../../module/quan-ly-thu-chi/quan-ly-thu-chi.module').then(
        (m) => m.QuanLyThuChiModule
      ),
  },
  {
    path: 'supplier',
    data: {
      title: 'Quản lý nhà cung cấp',
      breadcrumb: 'Quản lý nhà cung cấp',
    },
    loadChildren: () =>
      import('../../module/quan-ly-supplier/quan-ly-supplier.module').then(
        (m) => m.QuanLySupplierModule
      ),
  },
  {
    path: 'customer',
    data: {
      title: 'Quản lý khách hàng',
      breadcrumb: 'Quản lý khách hàng',
    },
    loadChildren: () =>
      import('../../module/quan-ly-customer/quan-ly-customer.module').then(
        (m) => m.QuanLyCustomerModule
      ),
  },
  {
    path: 'phan-quyen',
    data: {
      title: 'Phân quyền',
      breadcrumb: 'Phân quyền',
    },
    loadChildren: () =>
      import('../../module/phan-quyen/phan-quyen.module')
        .then(m => m.PhanQuyenModule)
  },
  {
    path: 'thong-tin-ca-nhan',
    data: {
      title: 'Thông tin cá nhân',
      breadcrumb: 'Thông tin cá nhân',
    },
    loadChildren: () =>
      import('../../module/thong-tin-ca-nhan/thong-tin-ca-nhan.module')
        .then(m => m.ThongTinCaNhanModule)
  },
];