import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { CRMQuanHuyenClient, CRM_QuanHuyenDto } from '../../api-connector/sso-connector';
import { QuanHuyenService } from '../../services/quan-huyen.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'quan-huyen-control',
  templateUrl: './quan-huyen-control.component.html',
  styleUrls: ['./quan-huyen-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuanHuyenControlComponent),
      multi: true,
    }]
})
export class QuanHuyenControlComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() readonly: boolean;

  @Input() required: boolean;


  @Input() maTinhThanh: string;

  @Input() showOld: string;

  @Input() appearance: string;

  // tslint:disable-next-line: no-output-native
  @Output() change = new EventEmitter<CRM_QuanHuyenDto>();

  control = new FormControl('');

  selected: CRM_QuanHuyenDto = null;
  filtered: Observable<CRM_QuanHuyenDto[]>;
  // tslint:disable-next-line: variable-name
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(private service: QuanHuyenService, private quanHuyenClient: CRMQuanHuyenClient) { }

  ngOnInit() {
    this.filtered = this.control.valueChanges.pipe(
      startWith<string | CRM_QuanHuyenDto>(''),
      map(value => value ? value : ''),
      map(value => typeof value === 'string' ? value : value.tenQuanHuyen),
      map(value => {
        if (!value) {
          return [];
        }
        return this.service.filterCRMWithTag(value, this.maTinhThanh);
      })
    );
    this.control.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((value) => {
      if (value instanceof CRM_QuanHuyenDto) {
        this.selected = value;
      } else {
        this.selected = null;
      }
      this.outputValue();
    });
  }
  ngOnDestroy(): void {
    this._onDestroy.next();
  }
  writeValue(code: any): void {
    if (code) {
      if (!this.selected || this.selected.maQuanHuyen !== code) {
        this.quanHuyenClient.getDetail(code).subscribe(x => {
          this.control.setValue(x);
        })
        // this.control.setValue(this.service.findByCode(code));
      }
    } else {
      this.control.setValue(code);
    }
    this.outputValue();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }
  display(district?: CRM_QuanHuyenDto): string {
    return district ? `${district.tenQuanHuyen}` : '';
  }

  clear() {
    this.control.setValue('');
    this.selected = null;
  }
  onTouched = () => { };

  onChange = (date: any) => { };

  outputValue() {
    this.change.emit(this.selected);
    this.onChange(this.selected);
    this.onTouched();
  }
}
