import { Injectable } from '@angular/core';
import { CRMQuanHuyenClient, CRM_QuanHuyenDto } from '../api-connector/sso-connector';
import { change_alias } from '../functions';

@Injectable({
  providedIn: 'root'
})
export class QuanHuyenService {
  lstCrmQuanHuyen: CRM_QuanHuyenDto[] = [];
  constructor(
    private crmQuanHuyenClient: CRMQuanHuyenClient
    ) {
    this.getAll();
  }

  private getAll() {
    this.crmQuanHuyenClient.getAll().subscribe(x => this.lstCrmQuanHuyen = x);
  }
  filterCRMWithTag(value: string = '', maTinhThanh: string = ''): CRM_QuanHuyenDto[] {

    const filterValue = change_alias(value).trim();
    const res: CRM_QuanHuyenDto[] = [];
    let lst = this.lstCrmQuanHuyen;
    if (maTinhThanh) {
      lst = lst.filter(x => x.maTinhThanh === maTinhThanh);
    }
    lst.forEach(x => {
      let point = 0;

      if (x.tenDayDuKhongDau.includes(filterValue)) {
        point += 4;
      }

      if (x.maQuanHuyen === value) {
        point += 10;
      }

      let isMatch = true;
      let tagPoint = 0;
      filterValue.split(' ')
        .filter(y => y !== '')
        .forEach(y => {
          const tag = x.lstSearchTag.find(z => z.key === y);
          if (tag != null) {
            tagPoint += tag.point;
          } else {
            isMatch = false;
          }
        });

      x.point = isMatch ? point + tagPoint : point;

      if (x.point >= 3) {
        res.push(x);
      }
    });
    return res.sort((x, y) => y.point - x.point);
  }

  findByCode(value: any): any {
    if (this.lstCrmQuanHuyen.length == 0) {
      this.getAll();
    }
    if (value) {
      const array = this.lstCrmQuanHuyen.filter(option => option.maQuanHuyen === value);
      return array.length > 0 ? array[0] : null;
    }
    return null;
  }
}
