import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SwaggerException } from '../api-connector/sso-connector';
import { HttpStatusCodeEnum } from '../enums/http-status-code-enum';
import { AlertService } from '../../shared/services/alert.service';
import { AuthService } from '../../shared/services/auth.service';
// errors-handler.ts
@Injectable()
export class ErrorsHandler implements ErrorHandler {

  constructor(
    // Because the ErrorHandler is created before the providers, we’ll have to use the Injector to get them.
    private injector: Injector,
  ) { }

  handleError(error: Error | HttpErrorResponse | SwaggerException) {

    const alertService = this.injector.get(AlertService);
    const authService = this.injector.get(AuthService);

    if (SwaggerException.isSwaggerException(error)) {
      if (error.status === HttpStatusCodeEnum.InternalServerError) {
        alertService.error('Hệ thống có lỗi');
      } else if (error.status === HttpStatusCodeEnum.BussinessException) {
        alertService.showError(error.response);
      } else if (error.status === HttpStatusCodeEnum.Unauthorized) {
        alertService.error('Phiên đăng nhập đã hết hạn');
        authService.logout();
      }
      else if (error.status === HttpStatusCodeEnum.GONE) {
        alertService.error('Đã quá thời gian làm việc rồi !!!');
        authService.logout();
      }
      else if (error.status === HttpStatusCodeEnum.Forbidden) {
        authService.logout();
      } else {
        alertService.error(error.response);
      }
    } else if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
        return alertService.error('Không có kết nối mạng');
      } else {
        // Handle Http Error (error.status === 403, 404...)
        return alertService.error(`${error.status} - ${error.message}`);
      }
    } else {
      // Handle Client Error (Angular Error, ReferenceError...)
    }
    // Log the error anyway
    console.error('It happens: ', error);
  }
}
