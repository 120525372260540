import { Component, EventEmitter, forwardRef, Inject, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { API_BASE_URL } from '../../api-connector/sso-connector';
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'editor-control',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorComponent),
      multi: true,
    },
  ]
})
export class EditorComponent implements OnInit, ControlValueAccessor, OnDestroy {
  editorConfig: any;

  @Input() readonly: boolean;

  // tslint:disable-next-line: no-output-native
  @Output() change = new EventEmitter<string>();

  control = new FormControl('');
  // tslint:disable-next-line: variable-name
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(@Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.editorConfig = {
      plugins: 'dialogui,dialog,about,sourcearea,a11yhelp,dialogadvtab,basicstyles,bidi,blockquote,notification,button,toolbar,clipboard,panelbutton,panel,floatpanel,colorbutton,colordialog,templates,menu,contextmenu,copyformatting,div,resize,elementspath,enterkey,entities,popup,filetools,filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo,font,forms,format,horizontalrule,iframe,wysiwygarea,image,indent,indentblock,indentlist,smiley,justify,menubutton,language,link,list,liststyle,magicline,maximize,pagebreak,pastetext,pastefromword,print,removeformat,selectall,showblocks,showborders,specialchar,scayt,stylescombo,tab,table,tabletools,tableselection,undo,lineutils,widgetselection,widget,notificationaggregator,uploadwidget,uploadimage,wsc,uploadfile',
      skin: 'moono-lisa',
      uploadUrl: `${baseUrl}/api/File/CkEditorUploadImage`,
      filebrowserUploadUrl: `${baseUrl}/api/File/CkEditorUploadImage`,
      fullPage: false,
      language: 'vi',
      imageResize: { maxWidth: 500, maxHeight: 500 },
      allowedContent:true,
      mediaEmbed: {
        previewsInData: true
    }
    };
  }

  ngOnInit(): void {
    this.control.valueChanges.subscribe(x => this.outputValue());
  }
  ngOnDestroy(): void {
    this._onDestroy.next();
  }
  writeValue(value: any): void {
    this.control.setValue(value);
    this.outputValue();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }
  onTouched = () => { };

  onChange = (date: any) => { };

  outputValue() {
    this.change.emit(this.control.value);
    this.onChange(this.control.value);
    this.onTouched();
  }
  onBlur() {
    this.onTouched();
  }
}
