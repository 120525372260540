import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { AccountClient, AccountDto } from '../../api-connector/sso-connector';

@Component({
  selector: 'app-account-control',
  templateUrl: './account-control.component.html',
  styleUrls: ['./account-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AccountControlComponent),
      multi: true,
    }
  ]
})
export class AccountControlComponent implements OnInit {
  control = new FormControl('');
  @Input() label: string;
  @Input() required: boolean;
  @Input() submitted: boolean;
  @Input() isOutputText: boolean;
  @Input() disable: boolean;
  @Input() onlyParent: boolean = false;
  @Input() reload: string = '';
  @Input() id: string = '';
  @Input() allowLoadDefault: boolean = true;
  @ViewChild('inputAutoAccount', { read: MatAutocompleteTrigger }) autoComplete: MatAutocompleteTrigger;
  @ViewChild('inputAutoAccount') input: ElementRef;

  private onDestroy$: Subject<void> = new Subject<void>();
  filtered: Observable<AccountDto[]>;
  constructor(
    private accountClient: AccountClient,
  ) { }
  accountDto: AccountDto;
  ngOnInit(): void {
    if (this.id) {
      this.accountClient.detail(this.id).subscribe(x => {
        this.control.setValue(x);
      });
    } else {
      if (this.allowLoadDefault) {
        this.accountClient.currentAccount().subscribe(x => {
          this.control.setValue(x);
        });
      }
    }

    this.filtered = this.control.valueChanges.pipe(
      distinctUntilChanged(),
      startWith<string | AccountDto>(''),
      map(value => value ? value : ''),
      map(value => typeof value === 'string' ? value : value.fullName),
      switchMap(value => {
        // if (!value) {
        //   return [];
        // }

        let result = this.accountClient.getAccountByKeySearch(value);
        return result;
      })
    );
    this.control.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.outputValue());
  }
  outputValue(): void {
    let value = this.control.value;
    if (!value || !(value instanceof AccountDto) && !this.isOutputText) {
      value = null;
    }
    this.onChange(value);
    this.onTouched();
  }
  optionSelected(event: MatAutocompleteSelectedEvent) {
    const selectedValue = event.option.value;
    this.accountDto = selectedValue;
    // this.control.setValue(selectedValue);
    //console.log(selectedValue);
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
  onChange: any = (value: any) => { };
  onTouched: any = () => { };

  writeValue(obj: string | AccountDto): void {
    if (obj instanceof AccountDto) {
      this.control.setValue(obj);
    }
    if (obj === null || obj === undefined || obj === '') {
      this.control.reset();
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }
  display = (account?: AccountDto) => {
    return account ? `${account.fullName}` : '';
  }
  validate(c: FormControl): any {
    return this.control.errors;
  }

  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/
    //Write your code here
    // console.log("reload", this.reload);
    if (this.reload != '' && this.reload != null && this.reload != undefined) {
      this.filtered = this.control.valueChanges.pipe(
        distinctUntilChanged(),
        startWith<string | AccountDto>(''),
        map(value => value ? value : ''),
        map(value => typeof value === 'string' ? value : value.fullName),
        switchMap(value => {
          // if (!value) {
          //   return [];
          // }

          let result = this.accountClient.getAccountByKeySearch(value);
          return result;
        })
      );
      this.control.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.outputValue());
    }
  }
  focusout() {
    if (!this.isOutputText) {
      const value = this.control.value;
      if (!value || !(value instanceof AccountDto) && !this.isOutputText) {
        this.input.nativeElement.value = '';
      }
    }
    setTimeout(() => {
      this.autoComplete.closePanel();
    }, 200);
  }
}
