import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';
import { CmsMenu, PermissionClient } from '../api-connector/sso-connector';

@Injectable({
	providedIn: 'root'
})

export class NavService {
	public screenWidth: any;
	public collapseSidebar = false;
	public fullScreen = false;
	items = new BehaviorSubject<CmsMenu[]>([]);

	constructor(private permissionClient: PermissionClient) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true;
		}
		this.getMenusForCMS();
	}
	public getMenusForCMS() {
		this.permissionClient.getMenusForCMS().subscribe(x => this.items.next(x));

	}
	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}
	checkPermission(key) {
		// console.log('this.items',this.items);
		return this.items.pipe(map(x => {
			return this.existsKey(x, key);
		}))
	}
	existsKey(menu: CmsMenu[], key: any): any {
		let exists = menu?.some(x => x.key === key);
		if (exists) {
			return true;
		}
		for (let index = 0; index < menu.length; index++) {
			const element = menu[index];

			if (element.functions && element.functions.some(f => f.key === key)) {
				return true;
			}
			if(element.children){
				exists = this.existsKey(element.children, key);
				if(exists){
					return true;
				}
			}
		}
		return false;
	}
}
