import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { CategoryClient, CheckNameExistsRequest, CreateCategoryRequest } from 'src/app/shared/api-connector/sso-connector';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-add-category-dialog',
  templateUrl: './add-category-dialog.component.html',
  styleUrls: ['./add-category-dialog.component.scss']
})
export class AddCategoryDialogComponent implements OnInit {

  nameCtrl = new FormControl('', {
    validators: [Validators.required],
    updateOn: 'change',
    asyncValidators: [this.validateCategoryNameNotTaken.bind(this)]
  });
  categoryCtrl = new FormControl();

  constructor(
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<AddCategoryDialogComponent>,
    private alertService: AlertService,
    private categoryClient: CategoryClient) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  validateCategoryNameNotTaken(control: FormControl): any {
    if (control.value) {
      return this.categoryClient.checkCategoryNameExists(new CheckNameExistsRequest({
        name: this.nameCtrl.value
      })).pipe(
        map(res => {
          return res ? { categoryNameTaken: true } : null;
        }));
    } else {
      return of(null);
    }
  }

  onSubmit() {
    // console.log(this.categoryCtrl.value);
    if (this.nameCtrl.valid) {
      this.spinner.show();
      this.categoryClient.create(new CreateCategoryRequest({
        ten: this.nameCtrl.value,
        parentId: this.categoryCtrl.value == null ? 0 : this.categoryCtrl.value.id,
      }))
        .pipe(finalize(() => this.spinner.hide()))
        .subscribe(x => {
          this.alertService.success('Thêm mới nhóm hàng thành công', 'Thêm nhóm hàng');
          this.dialogRef.close(this.nameCtrl.value);
        });
    }
  }
}
