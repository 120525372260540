export class PrintInvoicePdf {
    phone: string;
    customerName: string;
    orderTotalQty: number;
    orderTotal: number;
    discount: number;
    payingAmtInvoice: number;
    code: string;
    time: string;
    sellerName : string;
    paymentType: string;
}