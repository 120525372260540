import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { CRMTinhThanhClient, CRM_TinhThanhDto } from '../../api-connector/sso-connector';
import { TinhThanhService } from '../../services/tinh-thanh.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'tinh-thanh-control',
  templateUrl: './tinh-thanh-control.component.html',
  styleUrls: ['./tinh-thanh-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TinhThanhControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TinhThanhControlComponent),
      multi: true
    }
  ]
})
export class TinhThanhControlComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() readonly: boolean;

  @Input() required: boolean;

  @Input() isOutputText: boolean;

  @Input() maTinhThanh: string;

  @Input() showOld: string;

  @Input() appearance: string;

  // tslint:disable-next-line: no-output-native
  @Output() change = new EventEmitter<CRM_TinhThanhDto>();

  control = new FormControl('');

  selected: CRM_TinhThanhDto = null;
  filtered: Observable<CRM_TinhThanhDto[]>;
  // tslint:disable-next-line: variable-name
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(private service: TinhThanhService, private tinhThanhClient: CRMTinhThanhClient) { }

  ngOnInit() {

    this.filtered = this.control.valueChanges.pipe(
      startWith<string | CRM_TinhThanhDto>(''),
      map(value => value ? value : ''),
      map(value => typeof value === 'string' ? value : value.tenTinhThanh),
      map(value => {
        if (!value) {
          return [];
        }
        return this.service.filterCRMWithTag(value);
      })
    );
    this.control.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((value) => {
      if (value instanceof CRM_TinhThanhDto) {
        this.selected = value;
      } else {
        this.selected = null;
      }
      this.outputValue();
    });
  }
  ngOnDestroy(): void {
    this._onDestroy.next();
  }
  writeValue(code: string): void {
    if (code) {
      if (typeof code === 'string' && !this.selected || this.selected.maTinhThanh !== code) {
        this.tinhThanhClient.getDetail(code).subscribe(z => {
          this.control.setValue(z);
        }
        );
        // this.control.setValue(this.service.findByCode(code));
      }
    } else {
      this.control.setValue(code);
    }
    this.outputValue();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }
  display(provice?: CRM_TinhThanhDto): string {
    return provice ? `${provice.tenTinhThanh}` : '';
  }

  clear() {
    this.control.setValue('');
    this.selected = null;
  }
  onTouched = () => { };

  onChange = (date: any) => { };

  outputValue() {
    this.change.emit(this.selected);
    this.onChange(this.selected);
    this.onTouched();
  }
  validate(c: FormControl) {
    if (this.control.valid) {
      return null;
    } else {
      return this.control.validator;
    }
  }
}
