export const constant = {
    LOCALSTORAGE_KEY: 'shop-token',
    AVATAR_ID: 'avatarId',
    TOKEN_HEADER_KEY: 'authorization',
    FIREBASE_TOKEN: 'fb-token',
    SIZE_IMG: 10485760, //10
    SIZE_VIDEO: 52428800, //50mb
    SIZE_IMG_THUMB: 5242880, // 5mb
    VND_1000: 1000,
    VND_2000: 2000,
    VND_5000: 5000,
    VND_10000: 10000,
    VND_20000: 20000,
    VND_50000: 50000,
    VND_100000: 100000,
    VND_200000: 200000,
    VND_500000: 500000,
    BalanceDue: 0.00001,
};