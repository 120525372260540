import { Component, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { DanhMucThanhPhoQuanHuyenClient, DanhMucThanhPhoQuanHuyenDto, FilterWithTagDanhMucThanhPhoQuanHuyenRequest } from '../../api-connector/sso-connector';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ac-thanh-pho-quan-huyen-control',
  templateUrl: './ac-thanh-pho-quan-huyen-control.component.html',
  styleUrls: ['./ac-thanh-pho-quan-huyen-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AcThanhPhoQuanHuyenControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AcThanhPhoQuanHuyenControlComponent),
      multi: true,
    }
  ]
})
export class AcThanhPhoQuanHuyenControlComponent implements OnInit, OnDestroy, ControlValueAccessor {

  control = new FormControl('');
  @Input() label: string;
  @Input() required: boolean;
  @Input() submitted: boolean;
  $danhMucQuocGiaId: number;
  @Input() set danhMucQuocGiaId(value) {
    this.$danhMucQuocGiaId = value;
    if (value) {
      //console.log(value);
      this.createFilted();
    }
  }
  get danhMucQuocGiaId() {
    return this.$danhMucQuocGiaId;
  }
  @Input() isOutputText: boolean;
  @Input() disable: boolean;
  private onDestroy$: Subject<void> = new Subject<void>();
  filtered: Observable<DanhMucThanhPhoQuanHuyenDto[]>;
  @ViewChild('inputAutoThanhPhoQuanHuyen', { read: MatAutocompleteTrigger }) autoComplate: MatAutocompleteTrigger;

  constructor(
    private danhMucThanhPhoQuanHuyenClient: DanhMucThanhPhoQuanHuyenClient
  ) {

    if (this.required) {
      //console.log('a')
      this.control.setValidators([Validators.required]);
    }
  }

  ngOnInit(): void {
    this.createFilted();
    this.control.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.outputValue());

  }
  private createFilted() {
    this.filtered = this.control.valueChanges.pipe(
      distinctUntilChanged(),
      startWith<string | DanhMucThanhPhoQuanHuyenDto>(''),
      map(value => value ? value : ''),
      map(value => typeof value === 'string' ? value : value.tenThanhPhoQuanHuyen),
      switchMap(value => {
        // if (!value) {
        //   return [];
        // }
        return this.danhMucThanhPhoQuanHuyenClient.filterWithTag(new FilterWithTagDanhMucThanhPhoQuanHuyenRequest({
          danhMucQuocGiaId: this.danhMucQuocGiaId,
          keySearch: value
        }));
      })
    );
  }

  outputValue(): void {
    let value = this.control.value;
    if (!value || !(value instanceof DanhMucThanhPhoQuanHuyenDto) && !this.isOutputText) {
      value = null;
    }
    this.onChange(value);
    this.onTouched();
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
  onChange: any = (value: any) => { };
  onTouched: any = () => { };

  writeValue(obj: string | DanhMucThanhPhoQuanHuyenDto): void {
    if (obj instanceof DanhMucThanhPhoQuanHuyenDto) {
      this.control.setValue(obj);
    }
    if (obj === null || obj === undefined || obj === '') {
      this.control.reset();
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }
  display = (provice?: DanhMucThanhPhoQuanHuyenDto) => {
    return provice ? `${provice.tenThanhPhoQuanHuyen}` : '';
  }
  validate(c: FormControl): any {
    return this.control.errors;
  }
  focusout() {
    setTimeout(() => {
      this.autoComplate.closePanel();
    }, 200);
  }
}
