import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subject, Observable } from 'rxjs';
import { distinctUntilChanged, startWith, map, switchMap, takeUntil } from 'rxjs/operators';
import { ReceiptBookCategoryDto, ReceiptBookClient } from '../../api-connector/sso-connector';

@Component({
  selector: 'app-receipt-book-category',
  templateUrl: './receipt-book-category.component.html',
  styleUrls: ['./receipt-book-category.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReceiptBookCategoryComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReceiptBookCategoryComponent),
      multi: true,
    }
  ]
})
export class ReceiptBookCategoryComponent implements OnInit {
  control = new FormControl('');
  @Input() label: string;
  @Input() required: boolean;
  @Input() submitted: boolean;
  @Input() isOutputText: boolean;
  @Input() disable: boolean;
  @Input() reload: string = '';

  @ViewChild('inputAutoReceiptBookCategory', { read: MatAutocompleteTrigger }) autoComplete: MatAutocompleteTrigger;
  @ViewChild('inputAutoReceiptBookCategory') input: ElementRef;

  private onDestroy$: Subject<void> = new Subject<void>();
  filtered: Observable<ReceiptBookCategoryDto[]>;

  constructor(
    private receiptBookClient: ReceiptBookClient
  ) { }
  ngOnInit(): void {
    this.onFiltered();
  }
  outputValue(): void {
    let value = this.control.value;
    if (!value || !(value instanceof ReceiptBookCategoryDto) && !this.isOutputText) {
      value = null;
    }
    this.onChange(value);
    this.onTouched();
  }
  optionSelected(event: MatAutocompleteSelectedEvent) {
    // console.log(event);
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
  onChange: any = (value: any) => { };
  onTouched: any = () => { };

  writeValue(obj: string | ReceiptBookCategoryDto): void {
    if (obj instanceof ReceiptBookCategoryDto) {
      this.control.setValue(obj);
    }
    if (obj === null || obj === undefined || obj === '') {
      this.control.reset();
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }
  display = (item?: ReceiptBookCategoryDto) => {
    return item ? `${item.name}` : '';
  }
  validate(c: FormControl): any {
    return this.control.errors;
  }
  focusout() {
    if (!this.isOutputText) {
      const value = this.control.value;
      if (!value || !(value instanceof ReceiptBookCategoryDto) && !this.isOutputText) {
        this.input.nativeElement.value = '';
      }
    }
    setTimeout(() => {
      this.autoComplete.closePanel();
    }, 200);
  }

  onFiltered() {
    this.filtered = this.control.valueChanges.pipe(
      distinctUntilChanged(),
      startWith<string | ReceiptBookCategoryDto>(''),
      map(value => value ? value : ''),
      map(value => typeof value === 'string' ? value : value.name),
      switchMap(value => {
        // if (!value) {
        //   return [];
        // }
        let result = this.receiptBookClient.getReceiptBookCategory(value);
        return result;
      })
    );
    this.control.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.outputValue());
  }

  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/
    //Write your code here
    // console.log("reload", this.reload);
    if (this.reload != '' && this.reload != null && this.reload != undefined) {
      this.onFiltered()
    }
  }

}
