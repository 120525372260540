import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { constant } from '../constants/constant';

export class AddHeaderInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    const token = localStorage.getItem(constant.LOCALSTORAGE_KEY);
    if (token) {
      const clonedRequest = req.clone({
        headers: req.headers.set(constant.TOKEN_HEADER_KEY, token)
      });
      return next.handle(clonedRequest);
    }
    return next.handle(req);
  }
}
