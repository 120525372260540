import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { AuthenticationClient, TokenPayload } from '../api-connector/sso-connector';
import { constant } from '../constants/constant';
import { AlertService } from './alert.service';
import jwtDecode from 'jwt-decode';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private listMenuConfig = new BehaviorSubject<any>([]);
  currentmMenuConfig = this.listMenuConfig.asObservable();
  constructor(
    private router: Router,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private authenticationClient: AuthenticationClient) { }

  alreadyLogin(): boolean {
    return this.getToken() != null;
  }
  getToken(): string {
    return localStorage.getItem(constant.LOCALSTORAGE_KEY);
  }
  setToken(token: string): void {
    localStorage.setItem(constant.LOCALSTORAGE_KEY, token);
  }
  getFirebaseToken(): string {
    return localStorage.getItem(constant.FIREBASE_TOKEN);
  }
  routingDashboard() {
    this.router.navigate(['/redirect']);
  }

  routingOrder() {
    this.router.navigate(['/sale']);
  }

  getAccount(): TokenPayload {
    const token = this.getToken();
    if (token) {
      try {
        const tokenPayLoad: TokenPayload = TokenPayload.fromJS(jwtDecode(token));
        this.setAvatarId(tokenPayLoad.avatarId);
        return tokenPayLoad;
      } catch {
        this.alertService.error('Token đăng nhập không hợp lệ. Vui lòng đăng nhập lại');
        this.logoutClient();
      }
    }
  }

  laQuanTriVien(): boolean {
    return this.getAccount().laQuanTriVien;
  }

  getAvatarId() {
    if (localStorage.getItem(constant.AVATAR_ID)) {
      return localStorage.getItem(constant.AVATAR_ID);
    }
    return this.getAccount()?.avatarId;
  }

  setAvatarId(id) {
    localStorage.setItem(constant.AVATAR_ID, id);
    this.listMenuConfig.next(id);
  }

  logout() {
    this.spinner.show();
    this.authenticationClient.logOut()
      .pipe(finalize(() => {
        this.logoutClient();
        this.spinner.hide();
      }))
      .subscribe();
  }
  private logoutClient(): void {
    localStorage.removeItem(constant.LOCALSTORAGE_KEY);
    localStorage.removeItem(constant.AVATAR_ID);
    localStorage.removeItem(constant.FIREBASE_TOKEN);
    localStorage.removeItem(constant.TOKEN_HEADER_KEY);
    this.alertService.success('Tài khoản của bạn đã bị đăng xuất', 'Đăng xuất tài khoản');
    this.routingDashboard();
  }
}
