import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { AddCategoryDialogComponent } from 'src/app/module/quan-ly-san-pham/dialog/add-category-dialog/add-category-dialog.component';
import { AddProductUnitDialogComponent } from 'src/app/module/quan-ly-san-pham/dialog/add-product-unit-dialog/add-product-unit-dialog.component';
import { CheckIsCodeExistsRequest, ImageEnum, ProductClient, ProductEnum, ProductRequest } from 'src/app/shared/api-connector/sso-connector';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UploadService } from 'src/app/shared/services/upload.service';

@Component({
  selector: 'app-add-new-hang-hoa',
  templateUrl: './add-new-hang-hoa.component.html',
  styleUrls: ['./add-new-hang-hoa.component.scss']
})
export class AddNewHangHoaComponent implements OnInit {
  myFormAddHangHoa = new FormGroup({
    codeCtrl: new FormControl('', {
      updateOn: 'change',
      asyncValidators: [this.validateCodeNotTaken.bind(this)],
    }),

    titleCtrl: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change',
      asyncValidators: [this.validateTitleNotTaken.bind(this)],
    }),

    categoryCtrl: new FormControl('', [Validators.required]),
    priceCtrl: new FormControl(0, [
      Validators.required,
      Validators.min(1),
    ]),
    priceCostCtrl: new FormControl(0, [
      Validators.required,
      Validators.min(1),
    ]),
    isHomepageCtrl: new FormControl(true),
    productUnitCtrl: new FormControl(''),
    inventoryCtrl: new FormControl(0)
  });

  isBatchExpireControl = false;
  ctrls = this.myFormAddHangHoa.controls;
  isLoadingAvatar: boolean;
  avatarId: string;
  lstImage = [];
  totalFile = 0;
  reloadCategoryComponent = null;
  reloadPositionComponent = null;
  reloadProductUnitComponent = null;
  reloadManufacturerComponent = null;
  @ViewChild('inputAvatar') $inputAvatar: ElementRef;
  scrollStrategy: ScrollStrategy;

  constructor(
    private productClient: ProductClient,
    private uploadService: UploadService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private readonly scrollStrategyOptions: ScrollStrategyOptions,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<AddNewHangHoaComponent>,
    private el: ElementRef
  ) { }

  ngOnInit(): void {
    this.scrollStrategy = this.scrollStrategyOptions.noop();
  }

  handleChange() {
    if (this.isBatchExpireControl) {
      this.ctrls.hangHoaThanhPhanCtrl.setValue('');
    }
  }

  close() {
    this.dialogRef.close();
    this.onReset();
  }

  onfocusInvalid() {
    for (const key of Object.keys(this.myFormAddHangHoa.controls)) {
      if (this.myFormAddHangHoa.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        invalidControl.focus();
        break;
      }
    }
  }

  onSubmit() {
    this.myFormAddHangHoa.markAllAsTouched();
    this.onfocusInvalid();
    if (this.myFormAddHangHoa.valid) {
      this.spinner.show();
      this.productClient.createOrUpdate(new ProductRequest({
        bodyUsedId: null,
        categoryId: this.ctrls.categoryCtrl.value ? this.ctrls.categoryCtrl.value.id : null,
        code: this.ctrls.codeCtrl.value,
        description: null,
        inventoryMax: 999999999,
        inventoryMin: 0,
        isHomepage: true,
        positionId: null,
        quocGiaId: null,
        title: this.ctrls.titleCtrl.value,
        weight: null,
        type: ProductEnum.HangHoa,
        price: this.ctrls.priceCtrl.value,
        priceCost: this.ctrls.priceCostCtrl.value,
        lstImage: this.lstImage,
        productUnitId: this.ctrls.productUnitCtrl.value ? this.ctrls.productUnitCtrl.value.id : null,
        inventory: this.ctrls.inventoryCtrl.value
      }))
        .pipe(finalize(() => this.spinner.hide()))
        .subscribe(x => {
          this.alertService.success('Thêm mới thuốc thành công', 'Thành công');
          this.dialogRef.close(this.ctrls.titleCtrl.value);
          this.onReset();
        });
    }
  }

  onReset() {
    this.myFormAddHangHoa.reset();
    setTimeout(() => {
      this.ctrls.isHomepageCtrl.setValue(true);
      this.ctrls.priceCtrl.setValue(0);
      this.ctrls.priceCostCtrl.setValue(0);
      this.ctrls.inventoryMaxCtrl.setValue(999999999);
      this.ctrls.inventoryMinCtrl.setValue(0);
      this.ctrls.weightCtrl.setValue(0);
    }, 300);
  }
  addProductUnit() {
    const dialogRef = this.dialog.open(AddProductUnitDialogComponent, {
      panelClass: 'custom-dialog',
      minWidth: '500px',
      disableClose: true,
      scrollStrategy: this.scrollStrategy
    });

    dialogRef.afterClosed().subscribe(x => {
      if (x) {
        this.reloadProductUnitComponent = x;
      }
    });
  }

  addCategoryDialog() {
    const dialogRef = this.dialog.open(AddCategoryDialogComponent, {
      panelClass: 'custom-dialog',
      minWidth: '500px',
      disableClose: true,
      scrollStrategy: this.scrollStrategy
    });

    dialogRef.afterClosed().subscribe(x => {
      if (x) {
        // this.onSearch();
        this.reloadCategoryComponent = x;
      }
    });
  }

  getUrlFile(id: string) {
    return this.uploadService.getUrlFile(id);
  }

  removeImage(imageId) {
    this.lstImage = this.lstImage.filter(x => x != imageId);
    this.totalFile = this.lstImage.length;
  }

  onMultipeFileChange(event) {
    var filesAmount = event.target.files.length;
    if (filesAmount > 5) {
      this.alertService.error("Không vượt quá 5 file", "Lỗi upload file");
      return;
    }
    this.totalFile = filesAmount;
    this.lstImage = [];
    if (filesAmount === 0) {
      return;
    }
    if (event.target.files && event.target.files[0]) {
      this.uploadService
        .uploadMultipeFile(event.target.files, 0, ImageEnum.None)
        .pipe(finalize(() => (this.isLoadingAvatar = false)))
        .subscribe((x: any) => {
          x.forEach((element) => {
            this.lstImage.push(element.Id);
          });
        });
    }
  }

  validateCodeNotTaken(control: FormControl): any {
    if (control.value) {
      return this.productClient.checkIsCodeExists(new CheckIsCodeExistsRequest({
        code: this.ctrls.codeCtrl.value,
      })).pipe(
        map(res => {
          return res ? { codeTaken: true } : null;
        }));
    } else {
      return of(null);
    }
  }

  validateTitleNotTaken(control: FormControl): any {
    if (control.value) {
      return this.productClient.checkIsTitleExists(new CheckIsCodeExistsRequest({
        code: this.ctrls.titleCtrl.value,
      })).pipe(
        map(res => {
          return res ? { titleTaken: true } : null;
        }));
    } else {
      return of(null);
    }
  }
}
