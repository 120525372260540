import { FormControl, ValidatorFn, ValidationErrors, AsyncValidatorFn } from '@angular/forms';

export function validateUrl(c: FormControl): any {
    if (!c.value) { return null; }
    const URL_REGEXP = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/);
    return URL_REGEXP.test(c.value) ? null : {
        url: {
            valid: false
        }
    };
}

export function validateMobilephone(c: FormControl): any {
    if (!c.value) { return null; }
    const MOBILEPHONE_REGEXP = new RegExp('^0[0-9]{9,10}$');
    return MOBILEPHONE_REGEXP.test(c.value) ? null : {
        mobilephone: {
            valid: false
        }
    };
}

export function validateEmailFormat(c: FormControl): any {
    if (!c.value) { return null; }
    const EMAIL_REGEXP = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
    return EMAIL_REGEXP.test(c.value) ? null : {
        emailFormat: {
            valid: false
        }
    };
}

export function validateLicense(c: FormControl): any {
    if (!c.value) { return null; }
    const LICENSE_REGEXP = new RegExp('^[0-9]{12}$');
    return LICENSE_REGEXP.test(c.value) ? null : {
        license: {
            valid: false
        }
    };
}

export function validateTaxCode(c: FormControl): any {
    if (!c.value) { return null; }
    const TAXCODE_REGEXP = new RegExp('^[0-9]{0,20}$');
    return TAXCODE_REGEXP.test(c.value) ? null : {
        taxCode: {
            valid: false
        }
    };
}


export function validatePassword(c: FormControl): any {
    if (!c.value) { return null; }
    const PASSWORD_REGEXP = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
    return PASSWORD_REGEXP.test(c.value) ? null : {
        password: {
            valid: false
        }
    };
}

export function validateDecimalNumber(c: FormControl): any {
    if (!c.value) { return null; }
    // const DECIMAL_NUMBER_REGEXP = new RegExp(/^\d*\.?,*\d*$/);
    const vS = String(c.value);
    const v = vS.replace(/,/g, '');
    return !Number.isNaN(Number(v)) ? null : {
        decimalNumber: {
            valid: false
        }
    };
}

export function validateNormalCharacters(c: FormControl): any {
    const NORMAL_CHARACTER_REGEXP = new RegExp(/^[a-zA-Z0-9]*$/);
    return NORMAL_CHARACTER_REGEXP.test(c.value) ? null : {
        normalCharacters: {
            valid: false
        }
    };
}

export function validateMoreThanZero(c: FormControl): any {
    if (!c.value) { return null; }
    const vS = String(c.value);
    const v = vS.replace(/,/g, '');
    return (Number(v) > 0) ? null : {
        moreThanZero: {
            valid: false
        }
    };
}

export function validateNotPositiveInteger(c: FormControl): any {
    if (!c.value) { return null; }
    return (Number(c.value) >= 0 && Number.isInteger(c.value)) ? null : {
        notPositiveInteger: {
            valid: false
        }
    };
}


export function requiredIf(required: { value: boolean }): any {
    //console.log(required.value);
    return (control: FormControl): ValidationErrors | null => {

        const value = control.value;
        if ((value == null || value === undefined || value === '') && required.value) {
            return {
                requiredIf: { condition: required.value }
            };
        }
        return null;
    };
}

export function requiredIfForAutocomplete(required: { value: boolean }, obj: { value: any }): ValidationErrors {

    return (control: FormControl): ValidationErrors | null => {

        if ((obj.value == null || obj.value === undefined || obj.value === '') && required.value) {
            return {
                requiredIf: { valid: false }
            };
        }
        return null;
    };
}

export function fileType(types: string[]): any {
    return (control) => {
        const arrType: string[] = control && control.value ? ((control.value)).files.map(f => f.type) : [];
        if (arrType.length === 0) { return null; }
        return (arrType.find(x => types.indexOf(x) === -1) == null)
            ? null
            : {
                fileType: {
                    actualType: arrType,
                    requiredType: types
                }
            };
    };
}

