import * as moment from 'moment';

export function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return converToBlob(byteArrays, contentType);
}
export function converToBlob(byteArrays: any, contentType) {
  return new Blob(byteArrays, { type: contentType });
}

export function change_alias(alias: string | undefined): string {
  if (alias === null || alias === undefined) {
    return '';
  }
  let str = alias;
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\'|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ');
  str = str.replace(/ + /g, ' ');
  str = str.trim();
  return str;
}

export function formatDateTimeZone(time: Date): Date {
  if (!time) {
    return null;
  }
  const timezone = new Date().getTimezoneOffset();
  return moment(time).add(-timezone, 'm').toDate();
}

export function convertEnumToArray(objEnum: any = null) {
  const map: { id: number; name: string }[] = [];
  for (const a in objEnum) {
    if (typeof objEnum[a] === 'number') {
      // tslint:disable-next-line: no-angle-bracket-type-assertion
      map.push({ id: <any>objEnum[a], name: a });
    }
  }
  return map;
}
export function extractContent(text: string) {
  const span = document.createElement('span');
  span.innerHTML = text;
  return span.textContent || span.innerText;
}

export function isEmptyOrNull(input): boolean {
  if (input == null || input === undefined) {
    return true;
  } else if (String(input).replace(/\s/g, '').length <= 0) {
    return true;
  }
  return false;
}
export function sleep(ms): Promise<any> {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export function splitKeysearch(str: string): string[] {
  if (str === null || str === undefined) {
    return [];
  }
  let match: RegExpExecArray | null;
  const regex = /[a-zA-Z0-9]+/g;
  const result = [];
  // tslint:disable-next-line: no-conditional-assignment
  while ((match = regex.exec(str)) != null) {
    result.push(match[0]);
  }
  return result.map(x => x.trim()).filter(x => x);
}
export function trimQuota(str: string): string {
  if (str !== null && str !== undefined && str.length > 2) {
    str = str.substring(1, str.length - 1);
  }
  return str;
}
export function compareObject<T, E>(value1: T, value2: E) {
  if (value2 === undefined && value1 === undefined) {
    return true;
  }
  if (value2 !== undefined && value1 === undefined) {
    return false;
  }
  if (value2 === undefined && value1 !== undefined) {
    return false;
  }
  const keys = Object.keys(value1);
  for (let index = 0; index < keys.length; index++) {
    const element = keys[index];
    if (<any>value1[element] !== <any>value2[element]) {
      return false;
    }
  }
  return true;
}
