import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { CheckIsCodeExistsRequest, CommonClient, CreateCustomerRequest, CRM_QuanHuyenDto, CRM_TinhThanhDto, CustomerClient, CustomerDto, FileTypeEnum, ImageEnum, ObjectEnumOfCustomerTypeEnum, ObjectEnumOfGenderEnum } from 'src/app/shared/api-connector/sso-connector';
import { constant } from 'src/app/shared/constants/constant';
import { validateMobilephone } from 'src/app/shared/custom-validation';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UploadService } from 'src/app/shared/services/upload.service';

@Component({
  selector: 'app-add-customer-dialog',
  templateUrl: './add-customer-dialog.component.html',
  styleUrls: ['./add-customer-dialog.component.scss']
})
export class AddCustomerDialogComponent implements OnInit {
  maTinhThanh: string;
  maQuanHuyen: string;
  myForm = new FormGroup({
    codeCtrl: new FormControl('', {
      updateOn: 'change',
      asyncValidators: [this.validateCodeNotTaken.bind(this)],
    }),
    nameCtrl: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    phoneCtrl: new FormControl('', {
      validators: [Validators.required, validateMobilephone, Validators.maxLength(50)],
    }),
    phoneSubCtrl: new FormControl('', {
      validators: [validateMobilephone, Validators.maxLength(50)],
    }),
    tinhThanhCodeCtrl: new FormControl(''),
    quanHuyenCodeCtrl: new FormControl(''),
    phuongXaCodeCtrl: new FormControl(''),
    dateOfBirthCtrl: new FormControl(''),
    maSoThueCtrl: new FormControl('', [Validators.maxLength(100)]),
    emailCtrl: new FormControl('', [Validators.maxLength(100)]),
    facebookCtrl: new FormControl('', [Validators.maxLength(2000)]),
    noteCtrl: new FormControl('', [Validators.maxLength(2000)]),
    addressCtrl: new FormControl('', [Validators.maxLength(2000)]),
    typeCtrl: new FormControl(''),
    genderCtrl: new FormControl(''),
  });
  lstGender: ObjectEnumOfGenderEnum[] = [];
  lstCustomerType: ObjectEnumOfCustomerTypeEnum[] = [];
  ctrls = this.myForm.controls;
  avatarId: string;
  isLoadingAvatar: boolean;
  @ViewChild('inputAvatar') $inputAvatar: ElementRef;
  constructor(
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<AddCustomerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commomClient: CommonClient,
    private uploadService: UploadService,
    private customerClient: CustomerClient,
    private el: ElementRef
  ) {
    this.getDataFields();
  }

  ngOnInit(): void {
    this.ctrls.tinhThanhCodeCtrl.valueChanges.subscribe(x => {
      //console.log(x)
      if (x instanceof CRM_TinhThanhDto && x) {
        this.maTinhThanh = x.maTinhThanh;
      } else {
        this.maTinhThanh = x;
      }
    });

    this.ctrls.quanHuyenCodeCtrl.valueChanges.subscribe(x => {
      //console.log(x)
      if (x instanceof CRM_QuanHuyenDto && x) {
        this.maQuanHuyen = x.maQuanHuyen;
      } else {
        this.maQuanHuyen = x;
      }
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onfocusInvalid() {
    for (const key of Object.keys(this.myForm.controls)) {
      if (this.myForm.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        invalidControl.focus();
        break;
      }
    }
  }

  onSubmit() {
    this.myForm.markAllAsTouched();
    this.onfocusInvalid();
  
    let name = '';
    if (this.ctrls.nameCtrl.value instanceof CustomerDto) {
      name = this.ctrls.nameCtrl.value.name;
    } else {
      name = this.ctrls.nameCtrl.value;
    }
    // console.log(name);
    if (this.myForm.valid) {
      this.spinner.show();
      this.customerClient.createOrUpdate(new CreateCustomerRequest({
        id: 0,
        name: name,
        code: this.ctrls.codeCtrl.value,
        phone: this.ctrls.phoneCtrl.value,
        phoneSub: this.ctrls.phoneSubCtrl.value,
        tinhThanhCode: this.ctrls.tinhThanhCodeCtrl.value.maTinhThanh,
        quanHuyenCode: this.ctrls.quanHuyenCodeCtrl.value.maQuanHuyen,
        phuongXaCode: this.ctrls.phuongXaCodeCtrl.value.maPhuongXa,
        dateOfBirth: this.ctrls.dateOfBirthCtrl.value,
        maSoThue: this.ctrls.maSoThueCtrl.value,
        email: this.ctrls.emailCtrl.value,
        facebook: this.ctrls.facebookCtrl.value,
        note: this.ctrls.noteCtrl.value,
        avartaId: this.avatarId,
        type: this.ctrls.typeCtrl.value,
        address: this.ctrls.addressCtrl.value,
        gender: this.ctrls.genderCtrl.value,
      }))
        .pipe(finalize(() => this.spinner.hide()))
        .subscribe(x => {
          this.alertService.success('Thêm mới khách hàng thành công', 'Thành công');
          this.dialogRef.close(x);
        });
    }
  }

  onChangeFileAvatar(event) {
    const file = event.target.files[0];
    this.$inputAvatar.nativeElement.value = '';
    if (file) {
      this.isLoadingAvatar = true;

      if (!file.type.toLowerCase().includes("image/")) {
        this.alertService.error('Sai định dạng ảnh. Mời bạn nhập lại');
        this.isLoadingAvatar = false;
        return;
      }

      if (file.size > constant.SIZE_IMG) {
        this.alertService.error('Tệp ảnh phải nhỏ hơn 10mb');
        this.isLoadingAvatar = false;
        return;
      }

      this.uploadService.uploadFile(file, FileTypeEnum.AvatarAccount, ImageEnum.AvatarType)
        .pipe(finalize(() => this.isLoadingAvatar = false))
        .subscribe(x => {
          if (x.isSuccess) {
            this.avatarId = x.id;
          } else {
            this.alertService.error("File upload không đúng định dạng", "Lỗi upload file");
          }
        });
    }
  }

  validateCodeNotTaken(control: FormControl): any {
    if (control.value) {
      return this.customerClient.checkIsCodeExists(new CheckIsCodeExistsRequest({
        code: this.ctrls.codeCtrl.value,
      })).pipe(
        map(res => {
          return res ? { codeTaken: true } : null;
        }));
    } else {
      return of(null);
    }
  }

  getDataFields() {
    forkJoin([this.commomClient.getListGender(), this.commomClient.getListCustomer()])
      .subscribe(x => {
        this.lstGender = x[0];
        this.ctrls.genderCtrl.setValue(this.lstGender[0].value);
        this.lstCustomerType = x[1];
        this.ctrls.typeCtrl.setValue(this.lstCustomerType[0].value);
      });
  }

  getUrlFile(id: string) {
    return this.uploadService.getUrlFile(id);
  }

  close() {
    this.dialogRef.close();
  }
}
