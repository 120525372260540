export enum HttpStatusCodeEnum {
  BussinessException = 422,
  InternalServerError = 500,
  Unauthorized = 401,
  Forbidden = 403,
  GONE = 410
}

export enum TrangThaiDonHangEnum {
  LUU_NHAP = 0,
  CHO_DUYET = 1,
  DA_DUYET = 2,
  TU_CHOI_DUYET = 3,
  THOAI_DUYET = 4,
  KHOA = 5,
  MO_KHOA = 6
}