export class PagesChangeValue {
    public pageSize!: number;
    public pageIndex!: number;
    constructor(data?: IPagesChangeValue) {
        this.pageIndex = data?.pageIndex || 0;
        this.pageSize = data?.pageSize || 10;
    }

}

export interface IPagesChangeValue {
    pageSize: number;
    pageIndex: number;
}
