import { Injectable } from '@angular/core';
import { CRMPhuongXaClient, CRM_PhuongXaDto } from './../api-connector/sso-connector';
import { change_alias } from '../functions';
@Injectable({
  providedIn: 'root'
})
export class PhuongXaService {

  lstCrmPhuongXa: CRM_PhuongXaDto[] = [];

  constructor(
    private crmPhuongXaClient: CRMPhuongXaClient
    ) {
    this.getAll();
  }
  getAll() {
    this.crmPhuongXaClient.getAll().subscribe(x => this.lstCrmPhuongXa = x);
  }
  filterCRMWithTag(value: string = '', maQuanHuyen: string = '', maTinhThanh: string = ''): CRM_PhuongXaDto[] {

    const filterValue = change_alias(value).trim();
    const res: CRM_PhuongXaDto[] = [];
    let lst = this.lstCrmPhuongXa;
    if (maTinhThanh) {
      lst = lst.filter(x => x.maTinhThanh === maTinhThanh);
    }
    if (maQuanHuyen) {
      lst = lst.filter(x => x.maQuanHuyen === maQuanHuyen);
    }
    lst.forEach(x => {
      let point = 0;
      if (x.tenDayDuKhongDau.includes(filterValue)) {
        point += 3;
      }
      if (x.tenPhuongXa.toLowerCase().trim().includes(value.toLowerCase().trim())) {
        point += 0.5;
      }
      if (change_alias(x.tenPhuongXa).trim().includes(filterValue)) {
        point += 0.5;
      }
      let isMatch = true;
      let tagPoint = 0;
      filterValue.split(' ')
        .filter(y => y !== '')
        .forEach(y => {
          const tag = x.lstSearchTag.find(z => z.key === y);
          if (tag != null) {
            tagPoint += tag.point;
          } else {
            isMatch = false;
          }
        });

      x.point = isMatch ? point + tagPoint : point;

      if (x.point >= 3) {
        res.push(x);
      }
    });
    return res.sort((x, y) => y.point - x.point);
  }
  findByCode(value: any): any {
    if (this.lstCrmPhuongXa.length == 0) {
      this.getAll();
    }
    if (value) {
      const array = this.lstCrmPhuongXa.filter(option => option.maPhuongXa === value);
      return array.length > 0 ? array[0] : null;
    }
    return null;
  }

  findNameByCode(value: string = ''): string {
    const a = this.findByCode(value);
    return a == null ? '' : a.tenPhuongXa;
  }
}
