import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {

  }
  ngOnInit() {
    const url = '/dashboard';
    if (this.authService.alreadyLogin()) {
      this.router.navigate([url]);
    } else {
      this.router.navigate(['/login']);
    }
  }
}
