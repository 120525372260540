import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ListOrderCustomerResponseModel } from 'src/app/shared/api-connector/sso-connector';

@Component({
  selector: 'app-detail-order-customer',
  templateUrl: './detail-order-customer.component.html',
  styleUrls: ['./detail-order-customer.component.scss']
})
export class DetailOrderCustomerComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DetailOrderCustomerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ListOrderCustomerResponseModel,
    ) { 
      console.log(data);
    }

  ngOnInit(): void {
  }
  close(){
    this.dialogRef.close();
  }
}
