import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Component } from '@angular/core';
import { MatDialogActions, MatDialogConfig } from '@angular/material/dialog';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor() {
  }

  ngOnInit(): void {
  }
  title = 'Shop';
}

