import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './components/loader/loader.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ContentLayoutComponent } from './components/layout/content-layout/content-layout.component';
import { FullLayoutComponent } from './components/layout/full-layout/full-layout.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { BookmarkComponent } from './components/bookmark/bookmark.component';
import { TranslateModule } from '@ngx-translate/core';
import { CustomizerComponent } from './components/customizer/customizer.component';
import { DragulaModule } from 'ng2-dragula';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import 'hammerjs';
import 'mousetrap';
import { MatSelectModule } from '@angular/material/select';


// services
import { NavService } from "./services/nav.service";
import { CustomizerService } from "./services/customizer.service";
// Directives
import { ToggleFullscreenDirective } from './directives/fullscreen.directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AcQuocGiaControlComponent } from './controls/ac-quoc-gia-control/ac-quoc-gia-control.component';
import { AcThanhPhoQuanHuyenControlComponent } from './controls/ac-thanh-pho-quan-huyen-control/ac-thanh-pho-quan-huyen-control.component';
import { PhuongXaControlComponent } from './controls/phuong-xa-control/phuong-xa-control.component';
import { QuanHuyenControlComponent } from './controls/quan-huyen-control/quan-huyen-control.component';
import { TinhThanhControlComponent } from './controls/tinh-thanh-control/tinh-thanh-control.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { EditorComponent } from './controls/editor/editor.component';
import { SafeHtmlPipe } from './pipes/safe-html';
import { MatCheckboxModule } from '@angular/material/checkbox';
// import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgxCurrencyModule } from 'ngx-currency';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PdfViewerModule } from 'ng2-pdf-viewer'; // <- import PdfViewerModule
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { CategoryControlComponent } from './controls/category-control/category-control.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BodyUsedControlComponent } from './controls/body-used-control/body-used-control.component';
import { PositionControlComponent } from './controls/position-control/position-control.component';
import { ProductUnitControlComponent } from './controls/product-unit-control/product-unit-control.component';
import { ManufacturerControlComponent } from './controls/manufacturer-control/manufacturer-control.component';
import { ProductSearchControlComponent } from './controls/product-search-control/product-search-control.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomerSearchControlComponent } from './controls/customer-search-control/customer-search-control.component';
import { SupplierControlComponent } from './controls/supplier-control/supplier-control.component';
import { PopupModule } from '@progress/kendo-angular-popup';
import { AccountControlComponent } from './controls/account-control/account-control.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { AccountSearchControlComponent } from './controls/account-search-control/account-search-control.component';
import { SuggestProductControlComponent } from './controls/suggest-product-control/suggest-product-control.component';
import { ReceiptBookCategoryComponent } from './controls/receipt-book-category/receipt-book-category.component';
import { ColorPickerModule } from 'ngx-color-picker';
@NgModule({
  declarations: [
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    BookmarkComponent,
    RightSidebarComponent,
    ContentLayoutComponent,
    FullLayoutComponent,
    FeatherIconsComponent,
    ToggleFullscreenDirective,
    BreadcrumbComponent,
    CustomizerComponent,
    PaginatorComponent,
    AcQuocGiaControlComponent,
    AcThanhPhoQuanHuyenControlComponent,
    PhuongXaControlComponent,
    QuanHuyenControlComponent,
    TinhThanhControlComponent,
    EditorComponent,
    SafeHtmlPipe,
    CategoryControlComponent,
    BodyUsedControlComponent,
    PositionControlComponent,
    ProductUnitControlComponent,
    ManufacturerControlComponent,
    ProductSearchControlComponent,
    CustomerSearchControlComponent,
    SupplierControlComponent,
    AccountControlComponent,
    AccountSearchControlComponent,
    SuggestProductControlComponent,
    ReceiptBookCategoryComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    DragulaModule.forRoot(),
    NgbModule,
    GalleryModule.forRoot(),
    FlexLayoutModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatAutocompleteModule,
    MatSelectModule,
    CKEditorModule,
    MatCheckboxModule,
    // NgxJsonViewerModule,
    NgxCurrencyModule,
    InfiniteScrollModule,
    PdfViewerModule,
    NgxExtendedPdfViewerModule,
    MatIconModule,
    MatTooltipModule,
    NgSelectModule,
    PopupModule,
    DateInputsModule,
    ColorPickerModule,
  ],
  exports: [
    LoaderComponent,
    FeatherIconsComponent,
    TranslateModule,
    FlexLayoutModule,
    MatDatepickerModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    AcQuocGiaControlComponent,
    AcThanhPhoQuanHuyenControlComponent,
    PhuongXaControlComponent,
    QuanHuyenControlComponent,
    TinhThanhControlComponent,
    MatSelectModule,
    EditorComponent,
    CKEditorModule,
    SafeHtmlPipe,
    MatCheckboxModule,
    // NgxJsonViewerModule,
    NgxCurrencyModule,
    InfiniteScrollModule,
    PdfViewerModule,
    NgxExtendedPdfViewerModule,
    CategoryControlComponent,
    MatIconModule,
    BodyUsedControlComponent,
    PositionControlComponent,
    MatTooltipModule,
    ManufacturerControlComponent,
    ProductUnitControlComponent,
    ProductSearchControlComponent,
    NgSelectModule,
    CustomerSearchControlComponent,
    SupplierControlComponent,
    PopupModule,
    AccountControlComponent,
    DateInputsModule,
    AccountSearchControlComponent,
    SuggestProductControlComponent,
    ReceiptBookCategoryComponent,
    ColorPickerModule,
  ],
  providers: [
    NavService,
    CustomizerService
  ]
})
export class SharedModule { }

