import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { CRMPhuongXaClient, CRM_PhuongXaDto } from '../../api-connector/sso-connector';
import { PhuongXaService } from '../../services/phuong-xa.service';
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'phuong-xa-control',
  templateUrl: './phuong-xa-control.component.html',
  styleUrls: ['./phuong-xa-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhuongXaControlComponent),
      multi: true,
    },
  ]
})
export class PhuongXaControlComponent implements OnInit, ControlValueAccessor, OnDestroy {

  @Input() readonly: boolean;

  @Input() required: boolean;

  @Input() maQuanHuyen: string;

  @Input() maTinhThanh: string;

  @Input() showOld: string;

  @Input() appearance: string;

  // tslint:disable-next-line: no-output-native
  @Output() change = new EventEmitter<CRM_PhuongXaDto>();
  control = new FormControl('');

  selected: CRM_PhuongXaDto = null;
  filtered: Observable<CRM_PhuongXaDto[]>;
  // tslint:disable-next-line: variable-name
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(private service: PhuongXaService, private phuongXaClient: CRMPhuongXaClient) {
  }

  ngOnInit() {
    this.filtered = this.control.valueChanges.pipe(
      startWith<string | CRM_PhuongXaDto>(''),
      map(value => value ? value : ''),
      map(value => typeof value === 'string' ? value : value.tenPhuongXa),
      map(value => {
        if (!value) {
          return [];
        }
        return this.service.filterCRMWithTag(value, this.maQuanHuyen, this.maTinhThanh).slice(0, 20);
      })
    );
    this.control.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((value) => {
      if (value instanceof CRM_PhuongXaDto) {
        this.selected = value;
      } else {
        this.selected = null;
      }
      this.outputValue();
    });
  }
  ngOnDestroy(): void {
    this._onDestroy.next();
  }
  writeValue(code: any): void {
    if (code) {
      if (!this.selected || this.selected.maPhuongXa !== code) {
        this.phuongXaClient.getDetail(code).subscribe(x => {
          this.control.setValue(x);
        })
        //this.control.setValue(this.service.findByCode(code));
      }
    } else {
      this.control.setValue(code);
    }
    this.outputValue();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }
  display(ward?: CRM_PhuongXaDto): string {
    if (ward) {
      return `${ward.tenPhuongXa}`;
    }
    return '';
  }
  onTouched = () => { };

  onChange = (date: any) => { };

  clear() {
    this.selected = null;
    this.control.setValue('');
  }

  outputValue() {
    this.change.emit(this.selected);
    this.onChange(this.selected);
    this.onTouched();
  }
}
