import { Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { DanhMucQuocGiaDto } from '../../api-connector/sso-connector';
import { DanhMucQuocGiaClient } from '../../api-connector/sso-connector';
import { sleep } from '../../functions';
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ac-quoc-gia-control',
  templateUrl: './ac-quoc-gia-control.component.html',
  styleUrls: ['./ac-quoc-gia-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AcQuocGiaControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AcQuocGiaControlComponent),
      multi: true,
    }
  ]
})
export class AcQuocGiaControlComponent implements OnInit, OnDestroy, ControlValueAccessor {

  control = new FormControl('');
  @Input() label: string;
  @Input() required: boolean;
  @Input() submitted: boolean;
  @Input() isOutputText: boolean;
  @Input() disable: boolean;
  @ViewChild('inputAutoQuocGia', { read: MatAutocompleteTrigger }) autoComplete: MatAutocompleteTrigger;
  @ViewChild('inputAutoQuocGia') input: ElementRef;

  private onDestroy$: Subject<void> = new Subject<void>();
  filtered: Observable<DanhMucQuocGiaDto[]>;
  constructor(
    private danhMucQuocGiaClient: DanhMucQuocGiaClient
  ) {

    if (this.required) {
      this.control.setValidators([Validators.required]);
    }
  }

  ngOnInit(): void {
    this.filtered = this.control.valueChanges.pipe(
      distinctUntilChanged(),
      startWith<string | DanhMucQuocGiaDto>(''),
      map(value => value ? value : ''),
      map(value => typeof value === 'string' ? value : value.tenQuocGia),
      switchMap(value => {
        // if (!value) {
        //   return [];
        // }
        return this.danhMucQuocGiaClient.getDanhMucQuocGiaByKeySearch(value);
      })
    );
    this.control.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.outputValue());

  }
  outputValue(): void {
    let value = this.control.value;
    if (!value || !(value instanceof DanhMucQuocGiaDto) && !this.isOutputText) {
      value = null;
    }
    this.onChange(value);
    this.onTouched();
  }
  optionSelected(event: MatAutocompleteSelectedEvent) {
    // console.log(event);
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
  onChange: any = (value: any) => { };
  onTouched: any = () => { };

  writeValue(obj: string | DanhMucQuocGiaDto): void {
    if (obj instanceof DanhMucQuocGiaDto) {
      this.control.setValue(obj);
    }
    if (obj === null || obj === undefined || obj === '') {
      this.control.reset();
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }
  display = (provice?: DanhMucQuocGiaDto) => {
    return provice ? `${provice.tenQuocGia}` : '';
  }
  validate(c: FormControl): any {
    return this.control.errors;
  }
  focusout() {
    if (!this.isOutputText) {
      const value = this.control.value;
      if (!value || !(value instanceof DanhMucQuocGiaDto) && !this.isOutputText) {
        this.input.nativeElement.value = '';
      }
    }
    setTimeout(() => {
      this.autoComplete.closePanel();
    }, 200);
  }
}
