export class Pager {
    public totalItems?: number;
    public pageSize?: number;
    public totalPages?: number;
    public startPage?: number;
    public endPage?: number;
    public isShowDotsPrefix?: boolean;
    public isShowDotsSuffix?: boolean;
    public isShowFirst?: boolean;
    public isShowLast?: boolean;
    constructor(data?: IPager) {
        this.totalItems = data?.totalItems;
        this.pageSize = data?.pageSize;
        this.totalPages = data?.totalPages;
        this.startPage = data?.startPage;
        this.endPage = data?.endPage;
        this.isShowDotsPrefix = data?.isShowDotsPrefix;
        this.isShowDotsSuffix = data?.isShowDotsSuffix;
        this.isShowFirst = data?.isShowFirst;
        this.isShowLast = data?.isShowLast;
    }
}

export interface IPager {
    totalItems?: number;
    pageSize?: number;
    totalPages?: number;
    startPage?: number;
    endPage?: number;
    isShowDotsPrefix?: boolean;
    isShowDotsSuffix?: boolean;
    isShowFirst?: boolean;
    isShowLast?: boolean;
}

