import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { Sale1Component } from './sale1/sale1.component';
import { SalesComponent } from './sales/sales/sales.component';
import { ContentLayoutComponent } from './shared/components/layout/content-layout/content-layout.component';
import { RedirectComponent } from './shared/components/redirect/redirect.component';
import { content } from "./shared/routes/content-routes";
import { AuthGuardService } from './shared/services/auth-guard.service';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'redirect',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'redirect',
    component: RedirectComponent
  },
  {
    path: 'sale',
    component: SalesComponent,
    canActivate: [AuthGuardService],
    children: content
  },
  // {
  //   path: 'sale1',
  //   component: Sale1Component,
  //   canActivate: [AuthGuardService],
  //   children: content
  // },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AuthGuardService],
    children: content
  },
 // {
  //   path: '',
  //   component: FullLayoutComponent,
  //   canActivate: [],
  //   children: full
  // },
  {
    path: '**',
    redirectTo: 'redirect'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy',
    useHash: true
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
